import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapState } from 'vuex'
import { Component, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { UserInstructionSet } from '@/interfaces/common.interface'
import { userInstructionSet_Imports_Edit } from '@/bootstrap/global/user-instructions'

@Component({
  name: 'AttendeesImportView',
  computed: {
    ...mapState('registration', ['currentImport', 'importErrors']),
    ...mapState('formbuilder', ['importEventFields']),
    ...mapState('email', ['emails'])
  },
  filters: {
    checkForAndRemoveUnderscores: function (value: string) {
      if (value?.includes('_')) {
        return value.replaceAll('_', ' ')
      } else {
        return value
      }
    }
  }
})

export default class AttendeesImportView extends GtrSuper {
    currentImport!: Record<string, any>;
    userInstructionSets: UserInstructionSet[] = userInstructionSet_Imports_Edit;
    importEventFields!: Record<string, any>;

    get emailOptions () {
      const emails = (this as any).emails || []
      if (emails.length) {
        const emailOptions: any[] = []
        for (let i = 0; i < emails.length; i++) {
          const email = emails[i]
          const emailName = this.handleEmailName(email.value.page_name)
          const emailValue = email.value.page_name
          if (emailValue !== 'admin_cancellation_notification' && emailValue !== 'admin_registration_notification') {
            emailOptions.push({
              text: emailName,
              value: emailValue
            })
          }
        }
        return emailOptions.sort((a, b) => a.text >= b.text ? 1 : -1)
      }
      return []
    }

    get totalFields (): number {
      if (this.$data.mapping?.length) {
        return this.$data.mapping.length
      }
      return 0
    }

    get unmappedFields (): number {
      if (this.$data.mapping?.length) {
        let numOfUnmapped = 0
        for (let i = 0; i < this.$data.mapping.length; i++) {
          if (this.$data.mapping[i] === undefined || this.$data.mapping[i] === null) {
            numOfUnmapped++
          }
        }
        return numOfUnmapped
      }
      return 0
    }

    get totalRows () {
      if (this.currentImport?.data) {
        if (this.$data.import_first_row) {
          return this.currentImport.data.length
        } else {
          return this.currentImport.data.length - 1
        }
      }
    }

    get importTableHeaders () {
      if (this.$data.mapping.length) {
        const headers: Record<string, any> = []
        for (let i = 0; i < this.$data.mapping.length; i++) {
          const header = this.$data.mapping[i]
          if (header) {
            headers.push({
              align: 'start',
              sortable: true,
              text: this.importEventFields?.[header]?.label ?? this.$options?.filters?.capitalizeAndRemoveUnderscores(header),
              value: header,
              class: 'header-no-wrap'
            })
          }
        }
        headers.unshift({
          align: 'start',
          sortable: true,
          text: '#',
          value: 'rowIndex',
          class: 'header-no-wrap'
        })
        headers.push({
          align: 'start',
          sortable: false,
          text: 'Result',
          value: 'result',
          class: 'header-no-wrap'
        })
        return headers
      }
      return []
    }

    get importTableData () {
      if (this.currentImport?.data?.length && this.currentImport?.row_result_mapping) {
        let data: any
        if (this.currentImport.import_first_row) {
          data = this.removeUnusedColumns(this.currentImport.data)
        } else {
          data = this.removeUnusedColumns(this.currentImport.data.slice(1))
        }
        const fields = [...this.$data.mapping].filter(field => field !== null)
        fields.unshift('rowIndex')
        fields.push('result')
        fields.push('error')
        fields.push('uuid')
        const tableData: Record<string, any> = []
        if (this.currentImport.import_first_row) {
          for (let i = 0; i < data.length; i++) {
            data[i].unshift(i)
                    this.currentImport.row_result_mapping[i]?.status ? data[i].push(this.currentImport.row_result_mapping[i].status) : data[i].push(null)
                    this.currentImport.row_result_mapping[i]?.error ? data[i].push(this.currentImport.row_result_mapping[i].error) : data[i].push(null)
                    data[i].push(this.currentImport.row_result_mapping[i]?.uuid)
                    const item: any = { ...data[i] }
                    for (let j = 0; j < fields.length; j++) {
                      const field = fields[j]
                      if (field === 'rowIndex') {
                        item[field] = item[j] + 1
                      } else {
                        item[field] = item[j]
                      }
                      delete item[j]
                    }
                    tableData.push(item)
          }
        } else {
          for (let i = 0; i < data.length; i++) {
            data[i].unshift(i + 1)
                    this.currentImport.row_result_mapping[i + 1]?.status ? data[i].push(this.currentImport.row_result_mapping[i + 1].status) : data[i].push(null)
                    this.currentImport.row_result_mapping[i + 1]?.error ? data[i].push(this.currentImport.row_result_mapping[i + 1].error) : data[i].push(null)
                    data[i].push(this.currentImport.row_result_mapping[i + 1]?.uuid)
                    const item: any = { ...data[i] }
                    for (let j = 0; j < fields.length; j++) {
                      const field = fields[j]
                      item[field] = item[j]
                      delete item[j]
                    }
                    tableData.push(item)
          }
        }
        return tableData
      }
      return []
    }

    get defaultActivationQty () {
      if (this.$store !== undefined && this.$store.state.event !== undefined && this.$store.state.event.eventAllContent !== undefined) {
        return this.$store.state.event.eventAllContent.lr_settings.default_activation_qty
      }
      return 3
    }

    get importHeaders () {
      if (this.$data.mapping?.length) {
        return this.$data.mapping.filter(field => !!field)
      }
      return []
    }

    get importData () {
      if (this.currentImport?.data?.length) {
        return this.removeUnusedColumns(this.currentImport.data.slice(1))
      }
      return []
    }

    get insertsData () {
      if (this.currentImport?.inserts?.length && this.importData?.length) {
        const insertDataFiltered = this.currentImport.inserts.map(insert => insert.savedRecord.participant_data)
        return this.importData.filter(data => {
          const insertsIncludingEmail = insertDataFiltered.filter(insertObject => data.includes(insertObject.email))
          if (insertsIncludingEmail.length > 0) {
            return true
          }
          return false
        })
      }
      return []
    }

    get updatesData () {
      if (this.currentImport?.updates?.length && this.importData?.length) {
        const updateDataFiltered = this.currentImport.updates.map(update => update.savedRecord.participant_data)
        return this.importData.filter(data => {
          const updatesIncludingEmail = updateDataFiltered.filter(updateObject => data.includes(updateObject.email))
          if (updatesIncludingEmail.length > 0) {
            return true
          }
          return false
        })
      }
      return []
    }

    get importTableAdds () {
      if (this.importTableData) {
        return this.importTableData.filter(item => item.result === 'CREATED')
      }
      return []
    }

    get importTableUpdates () {
      if (this.importTableData) {
        return this.importTableData.filter(item => item.result === 'UPDATED')
      }
      return []
    }

    get importTableFailures () {
      if (this.importTableData) {
        return this.importTableData.filter(item => item.result === 'FAILED')
      }
      return []
    }

    get totalAdded () {
      if (this.currentImport?.totals) {
        return this.currentImport.totals.total_added
      }
      return 0
    }

    get totalUpdated () {
      if (this.currentImport?.totals) {
        return this.currentImport.totals.total_updated
      }
      return 0
    }

    get totalFailed () {
      if (this.currentImport?.totals) {
        return this.currentImport.totals.total_failed
      }
      return 0
    }

    get importTabs () {
      return [
        {
          id: 0,
          text: `Adds (${this.totalAdded ? this.totalAdded : 0})`
        },
        {
          id: 1,
          text: `Updates (${this.totalUpdated ? this.totalUpdated : 0})`
        },
        {
          id: 2,
          text: `Failures (${this.totalFailed ? this.totalFailed : 0})`
        }
      ]
    }

    get totalJobs () {
      if (this.currentImport?.progress) {
        const indexOfTotalJobsFinished = this.currentImport.progress.indexOf('total jobs finished:')
        return this.currentImport.progress.substring(indexOfTotalJobsFinished + 21) // hack for now
      }
      return ''
    }

    get emptyStateMessage () {
      if (this.$data.importTab === 0) {
        return 'No adds.'
      } else if (this.$data.importTab === 1) {
        return 'No updates.'
      } else if (this.$data.importTab === 2) {
        return 'No failures.'
      }
      return 'No data available.'
    }

    data () {
      return {
        emailBlastModes: [
          {
            label: 'New Only',
            value: 'NEW_ONLY'
          },
          {
            label: 'All',
            value: 'ALL'
          }
        ],
        sendEmailData: {
          checkbox: false,
          enabled: false,
          modal: false,
          email: '',
          email_blast_mode: ''
        },
        addLeadsActivationCodeData: {
          checkbox: false,
          enabled: false,
          visible: false,
          modal: false,
          sendEmail: false,
          cap: 0
        },
        loading: false,
        submitting: false,
        import_first_row: 1,
        mode: '',
        base_update_on: '',
        import_errors: '',
        yes_no: [
          {
            label: 'Yes',
            value: 1
          },
          {
            label: 'No',
            value: 0
          }
        ],
        importModes: [
          {
            label: 'IMPORT ONLY',
            value: 'IMPORT_ONLY'
          },
          {
            label: 'IMPORT AND UPDATE',
            value: 'IMPORT_AND_UPDATE'
          }
        ],
        fieldsPrint: [],
        importFile: '',
        mapping: [],
        tableSearch: '',
        importTab: 0,
        _currentImport: null,
        fieldsIndexed: []
      }
    }

    created () {
      if (this.$bus !== undefined) {
        this.$bus.$on('activate-module', (module: string) => {
          this.determineLeadsActivationCodeVisibility(module === 'Lead Retrieval')
        })
      }
    }

    async mounted () {
      await Promise.all([
        this.$store.dispatch('email/fetchEmails', this.$route.params.event_uuid),
        this.getImportEventFields(),
        this.getImport()
      ])
      this.setActiveTab()
      const { LEADS: { enabled: leadsEnabled } = { enabled: false } } = this.$store.state?.module?.activatedEventModules || {}
      this.determineLeadsActivationCodeVisibility(leadsEnabled)
      this.$data.addLeadsActivationCodeData.cap = this.defaultActivationQty
    }

    determineLeadsActivationCodeVisibility (leadsEnabled: boolean) {
      if (leadsEnabled) {
        this.$data.addLeadsActivationCodeData.visible = true
      }
    }

    handleChangeAddLeadsActivationCode ($event: boolean) {
      if ($event) {
        this.$data.addLeadsActivationCodeData.modal = true
      } else {
        this.resetAddLeadsActivationCodeData()
      }
    }

    handleChangeSendEmail ($event: boolean) {
      if ($event) {
        this.$data.sendEmailData.modal = true
      } else {
        this.resetSendEmailData()
      }
    }

    resetAddLeadsActivationCodeData () {
      Object.assign(this.$data.addLeadsActivationCodeData, {
        checkbox: false,
        enabled: false,
        modal: false,
        sendEmail: false,
        cap: this.defaultActivationQty
      })
    }

    resetSendEmailData () {
      Object.assign(this.$data.sendEmailData, {
        checkbox: false,
        enabled: false,
        modal: false,
        email: '',
        email_blast_mode: ''
      })
    }

    handleOpenSendEmailModal () {
      this.$data.sendEmailData.modal = true
    }

    handleOpenLeadsActivationCodeModal () {
      this.$data.addLeadsActivationCodeData.modal = true
    }

    handleCloseSendEmailModal () {
      if (!this.$data.sendEmailData.enabled || !this.$data.sendEmailData.email) {
        this.resetSendEmailData()
      } else {
        this.$data.sendEmailData.modal = false
      }
    }

    handleCloseAddLeadsActivationCodeModal () {
      if (!this.$data.addLeadsActivationCodeData.enabled) {
        this.resetAddLeadsActivationCodeData()
      } else {
        this.$data.addLeadsActivationCodeData.modal = false
      }
    }

    addLeadsActivationCodeTrigger () {
      this.$data.addLeadsActivationCodeData.enabled = true
      this.$data.addLeadsActivationCodeData.modal = false
    }

    addSendEmailTrigger () {
      this.$data.sendEmailData.enabled = true
      this.$data.sendEmailData.modal = false
    }

    setActiveTab () {
      if (this.totalAdded === 0 && this.totalFailed === 0 && this.totalUpdated > 0) {
        this.$data.importTab = 1
      }
      if (this.totalFailed > 0) {
        this.$data.importTab = 2
      }
    }

    handleFilterAdded (): void {
      this.$data.importTab = 0
    }

    handleFilterUpdated (): void {
      this.$data.importTab = 1
    }

    handleFilterFailed (): void {
      this.$data.importTab = 2
    }

    handleTabSelection (tab: number): void {
      if (tab === 0) {
        this.handleFilterAdded()
      } else if (tab === 1) {
        this.handleFilterUpdated()
      } else if (tab === 2) {
        this.handleFilterFailed()
      }
    }

    handleGoBackToImports () {
      this.$router.push({
        name: 'level-two.event.attendees.import',
        params: {
          event_uuid: this.$route.params.event_uuid
        }
      })
    }

    getImportPanelTitle (importRow, importType) {
      const importDataFiltered = this.currentImport[importType].map(data => data.savedRecord.participant_data)
      const participantData = importDataFiltered.filter(participant => importRow.includes(participant.email))
      if (participantData.length > 0) {
        return `${participantData[0].first_name} ${participantData[0].last_name}`
      }
    }

    @Watch('importEventFields', { immediate: true })
    onImportEventFieldsChange (newVal: any) {
      if (newVal) {
        this.$data.fieldsIndexed = []
        this.$data.fieldsPrint = []
        for (const fieldIndex in newVal) {
          if (!this.excludedParticipantImportFields.includes(fieldIndex)) {
            const field = newVal[fieldIndex]
            this.$data.fieldsPrint.push({
              text: field.label,
              value: field.field,
              on_form: field.on_form,
              custom_field: field.custom_field
            })
            this.$data.fieldsIndexed[field.field] = field.field
          }
        }
        // Sort alphabetically and then add option groups to the end
        const fields = this.$data.fieldsPrint.filter(field => !field.text.includes('Option Group:'))
        const optionGroups = this.$data.fieldsPrint.filter(field => field.text.includes('Option Group:'))
        this.$data.fieldsPrint = fields.sort((a, b) => a.text >= b.text ? 1 : -1).concat(optionGroups)
      }
    }

    @Watch('importErrors', { immediate: true })
    onImportErrorsChange (newVal: any) {
      if (newVal) {
        this.$data.import_errors = newVal
      }
    }

    @Watch('currentImport', { immediate: true })
    onCurrentImportChange (newVal: any) {
      if (Object.keys(newVal).length) {
        const {
          mode = '',
          base_update_on = '',
          mapping = [],
          data = [[]],
          import_first_row = false,
          status = '',
          email_blast_enabled = 0,
          email_blast = '',
          add_activation_code = 0,
          activation_code_qty = 1,
          send_activation_code_email = 0
        } = newVal
        this.$data._currentImport = newVal
        this.$data.mode = mode
        this.$data.base_update_on = base_update_on
        if (mapping.length) {
          this.$data.mapping = mapping
        } else if (data[0].length) {
          this.$data.mapping = new Array(data[0].length)
        }
        this.$data.import_first_row = import_first_row ? 1 : 0
        Object.assign(this.$data.sendEmailData, {
          enabled: !!email_blast_enabled,
          email: email_blast,
          checkbox: !!email_blast_enabled
        })
        Object.assign(this.$data.addLeadsActivationCodeData, {
          enabled: !!add_activation_code,
          activation_code_qty,
          send_activation_code_email: send_activation_code_email ? 1 : 0,
          checkbox: !!add_activation_code
        })
        this.setActiveTab()
        if (status === 'FINISHED') {
          this.$data.submitting = false
        }
        this.mapFields()
      }
    }

    fileName (url) {
      const parts = url.split('/')
      return parts[parts.length - 1]
    }

    async startImport () {
      try {
        this.$data.submitting = true
        const payload = {
          event_uuid: this.$route.params.event_uuid,
          import_uuid: this.$route.params.import_uuid,
          data: {
            import_first_row: this.$data.import_first_row,
            mode: this.$data.mode,
            base_update_on: this.$data.base_update_on,
            mapping: this.$data.mapping,
            start_import: true,
            email_blast_enabled: this.$data.sendEmailData.enabled ? 1 : 0,
            email_blast: this.$data.sendEmailData.email,
            add_activation_code: this.$data.addLeadsActivationCodeData.enabled ? 1 : 0,
            send_activation_code_email: this.$data.addLeadsActivationCodeData.sendEmail ? 1 : 0,
            activation_code_qty: this.$data.addLeadsActivationCodeData.cap,
            email_blast_mode: this.$data.sendEmailData.email_blast_mode
          }
        }
        await this.$store.dispatch('registration/updateImport', payload)
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        if (this.currentImport.status === 'FINISHED') {
          this.$data.submitting = false
        }
      }
    }

    async updateImport () {
      try {
        this.$data.submitting = true
        const payload = {
          event_uuid: this.$route.params.event_uuid,
          import_uuid: this.$route.params.import_uuid,
          data: {
            import_first_row: this.$data.import_first_row,
            mode: this.$data.mode,
            base_update_on: this.$data.base_update_on,
            mapping: this.$data.mapping,
            start_import: false,
            email_blast_enabled: this.$data.sendEmailData.enabled ? 1 : 0,
            email_blast: this.$data.sendEmailData.email,
            add_activation_code: this.$data.addLeadsActivationCodeData.enabled ? 1 : 0,
            send_activation_code_email: this.$data.addLeadsActivationCodeData.sendEmail ? 1 : 0,
            activation_code_qty: this.$data.addLeadsActivationCodeData.cap
          }
        }
        await this.$store.dispatch('registration/updateImport', payload)
        Container.get(Notification).success('Import successfully saved.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }

    async downloadFileUrl () {
      window.location.href = this.currentImport.file_url
    }

    private removeUnusedColumns (data: any[][]): any[][] {
      const filteredData: any[][] = []
      const columnsToRemove: number[] = []
      this.$data.mapping.forEach((field, index: number) => {
        if (!field) {
          columnsToRemove.push(index)
        }
      })
      data.forEach((row: any[]) => {
        filteredData.push(row.filter((element, index) => !columnsToRemove.includes(index)))
      })
      return filteredData
    }

    private async getImportEventFields () {
      try {
        this.$data.loading = true
        const payload = {
          event_uuid: this.$route.params.event_uuid,
          table: 'participants'
        }
        await this.$store.dispatch('formbuilder/getImportEventFields', payload)
        this.mapFields()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }

    private allUndefined (arr) {
      return arr.every(element => element === undefined)
    }

    private mapFields () {
      const newVal = this.$data._currentImport
      if (this.$data.mapping !== undefined && Array.isArray(newVal?.data) && this.$data.mapping && this.allUndefined(this.$data.mapping) && (newVal?.data || []).length > 0) {
        const dataCur = newVal.data[0]
        for (const itemIndex in dataCur) {
          const item = dataCur[itemIndex]
          if (typeof item === 'string') {
            for (const fieldIn in this.$data.fieldsIndexed) {
              if (item.toLowerCase() === fieldIn) {
                this.$data.mapping[itemIndex] = fieldIn
              }
            }
          }
        }
      }
    }

    private async getImport () {
      try {
        this.$data.loading = true
        const payload = {
          event_uuid: this.$route.params.event_uuid,
          import_uuid: this.$route.params.import_uuid
        }
        await this.$store.dispatch('registration/getImport', payload)
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }
}
